



























import Component from "vue-class-component";
import List from "@/components/List";
import { ConfigItemResource } from "@/resources";
import { ConfigItem } from "@/resources/interfaces";

@Component({
  filters: {
    key(input: string) {
      return Object.keys(input)[0];
    }
  }
})
export default class ConfigList extends List<ConfigItem> {
  name = "config";
  resource = ConfigItemResource;

  showDetail(item: ConfigItem) {
    this.$router.push(`/config/${Object.keys(item)[0]}`);
  }

  mounted() {
    this.searchQuery = {
      seperate: true
    };
  }
}
