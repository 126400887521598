




















































































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { StoreResource, http } from "@/resources";
import { Store } from "@/resources/interfaces";
import { Editor, Poster } from "@/components";
import { confirm, promptInput } from "@/helpers/sweetAlert";
import CopyId from "@/components/CopyId.vue";

@Component({
  components: {
    Editor,
    Poster,
    CopyId
  },
  filters: {
    dayOfWeekName(n: number) {
      const names = ["日", "一", "二", "三", "四", "五", "六"];
      return names[n];
    },
    dailyLimitGroupName(group: "coupon" | "common") {
      const names = { coupon: "优惠活动", common: "会员散客" };
      return names[group];
    }
  }
})
export default class StoreDetail extends Vue {
  store: Partial<Store> = {
    id: "",
    dailyLimit: {
      common: [],
      coupon: [],
      dates: []
    }
  };
  newDailyLimitDateItem: { date?: string; group?: string; limit?: number } = {};
  async save() {
    this.store = await StoreResource.save(this.store);
    this.$notify({
      message: "保存成功",
      icon: "check",
      type: "success"
    });
    if (this.$route.params.id === "add") {
      this.$router.replace(`/store/${this.store.id}`);
    }
  }
  async remove() {
    if (
      !(await confirm(
        "确定要删除这个门店",
        `这个操作不可撤销`,
        "确定删除",
        "error"
      ))
    )
      return;
    await StoreResource.delete({ id: this.store.id });
    this.$router.back();
  }
  addNewDailyLimitDateItem() {
    if (!this.store.dailyLimit?.dates) return;
    this.store.dailyLimit.dates.push(this.newDailyLimitDateItem);
    this.newDailyLimitDateItem = {};
  }
  removeDailyLimitDateItem(index: number) {
    if (!this.store.dailyLimit?.dates) return;
    const dates = this.store.dailyLimit.dates;
    this.store.dailyLimit.dates = dates
      .slice(0, index)
      .concat(dates.slice(index + 1));
  }
  async setDoor(action: "init" | "open" | "auth", payload: any) {
    const data: Record<string, any> = {};
    if (action === "init") {
      if (
        !(await confirm(
          `确定要初始化所有闸机控制板吗？`,
          `将删除 ${this.store.name} 所有已授权手环/卡，并重新授权管理卡`
        ))
      ) {
        return;
      }
      data.init = true;
    } else if (action === "auth") {
      const no = await promptInput(
        "输入或扫描卡号",
        "授权该卡号通行闸机",
        "授权",
        "question",
        "text",
        undefined,
        (v: string) => {
          if (v.length < 8) return "至少8位";
          return false;
        }
      );
      if (no === undefined) return;
      data.auth = no;
    } else if (action === "open") {
      data.open = payload;
    }
    await http.post(`/store/${this.store.id}/door`, data);
    this.$notify({
      message: "操作成功",
      icon: "check",
      type: "success"
    });
  }
  async mounted() {
    if (this.$route.params.id !== "add") {
      this.store = await StoreResource.get({ id: this.$route.params.id });
    }
  }
}
