























































import { RoleResource } from "@/resources";
import { Role } from "@/resources/interfaces";
import List from "@/components/List";
import Component from "vue-class-component";

@Component
export default class RoleList extends List<Role> {
  name = "role";
  resource = RoleResource;
}
