import { render, staticRenderFns } from "./PostDetail.vue?vue&type=template&id=33a922df&scoped=true&lang=pug&"
import script from "./PostDetail.vue?vue&type=script&lang=ts&"
export * from "./PostDetail.vue?vue&type=script&lang=ts&"
import style0 from "./PostDetail.vue?vue&type=style&index=0&id=33a922df&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33a922df",
  null
  
)

export default component.exports