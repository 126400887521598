

















import Vue from "vue";
import { Component } from "vue-property-decorator";
// @ts-ignore
import JSONEditor from "jsoneditor";
import { ConfigItemResource } from "@/resources";

@Component
export default class Config extends Vue {
  key = "";
  editor: any = null;
  async save() {
    await ConfigItemResource.update(
      { key: this.$route.params.key },
      this.editor.get()
    );

    this.$notify({
      message: "保存成功",
      icon: "check",
      type: "success"
    });
  }
  async mounted() {
    this.key = this.$route.params.key;
    const config = await ConfigItemResource.get({
      key: this.$route.params.key
    });
    let value = config[this.key];
    if (typeof value !== "object") {
      value = { [this.key]: value };
    }
    this.editor = new JSONEditor(
      this.$refs.jsonEditor,
      { modes: ["tree", "code"] },
      value
    );
    this.editor.expandAll();
  }
}
