

















































import { PostResource } from "@/resources";
import { Post } from "@/resources/interfaces";
import List from "@/components/List";

export default class PostList extends List<Post> {
  name = "post";
  resource = PostResource;
}
