


































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { PostResource } from "@/resources";
import { Post } from "@/resources/interfaces";
import { Editor, Poster } from "@/components";
import { confirm } from "@/helpers/sweetAlert";

@Component({
  components: {
    Editor,
    Poster
  }
})
export default class PostDetail extends Vue {
  post: Partial<Post> = { id: "", tags: [], start: null, end: null };
  async save() {
    this.post = await PostResource.save(this.post);
    this.$notify({
      message: "保存成功",
      icon: "check",
      type: "success"
    });
    if (this.$route.params.id === "add") {
      this.$router.replace(`/post/${this.post.id}`);
    }
  }
  async remove() {
    if (
      !(await confirm(
        "确定要删除这篇文章",
        `这个操作不可撤销`,
        "确定删除",
        "error"
      ))
    )
      return;
    await PostResource.delete({ id: this.post.id });
    this.$router.go(-1);
  }
  async mounted() {
    if (this.$route.params.id !== "add") {
      this.post = await PostResource.get({ id: this.$route.params.id });
    }
  }
}
